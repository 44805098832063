import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;
  @media screen and (min-width: 1024px) {
    position: relative;
    max-width: 1445px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1700px) {
    max-width: 1260px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 1620px;
  }
`

export const BannerTopo = styled.div`
  position: relative;
  cursor:pointer;

  img {
    width: 100%;
    height: auto;
  }
`
export const DescriptionWrap = styled.div`
  position: absolute;
  top: 110px;
  right: 0;
  z-index: 1;
  font-family: 'ASICSFont3.0-Regular', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  p {
    color: #413248;
    font-size: 30px;
    font-family: 'ASICSFont3.0-Italic';
    @media (min-width: 1024px) and (max-width: 1366px) {
      font-size: 35px;
      line-height: 45px;
    }
  }

  h1 {
    font-family: 'ASICSFont3.0-Bold', sans-serif;
    font-size: 82px;
    color: #be65a8;
    letter-spacing: -0.5px;
    line-height: 70px;
    text-align: right;
    margin-top: 25px;
    @media screen and (min-width: 1700px) {
      font-size: 98px;
      line-height: 88px;
    }

    span {
      font-size: 130px;
      @media screen and (min-width: 1700px) {
        font-size: 154px;
      }

      @media screen and (max-width: 767px) {
        font-size: 80px;
      }
    }
  }

  .persons {
    color: #413248;
    font-size: 20px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      margin-top: 10px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1366px) {
      margin-top: 140px;
    }
    @media screen and (min-width: 1367px) and (max-width: 1536px) {
      margin-top: 230px;
    }
    @media screen and (min-width: 1537px) {
      margin-top: 150px;
    }
    @media screen and (min-width: 1700px) {
      margin-top: 235px;
    }
  }

  article {
    font-size: 18px;
    color: #acacb2;
    margin-bottom: 10px;
  }

  a {
    background: #57416d;
    padding: 10px 45px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: 'ASICSFont3.0-Regular', sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    &:hover {
      background: #5dc095;
      color: #fff;
    }

    @media screen and (min-width: 1700px) {
      margin-top: 15px;
    }
  }

  @media (max-width: 767px) {
    top: 20px;
    right: 15px;
    bottom: auto;
    p {
      font-size: 33px;
      line-height: normal;
    }
    h1 {
      font-size: 60px;
      line-height: 60px;
    }

    a {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    top: auto;
    left: 40px;
    bottom: 120px;
  }

  @media (min-width: 990px) and (max-width: 1024px) {
    left: 20px;
    p {
      font-size: 30px;
      line-height: 45px;
    }

    h1 {
      font-size: 45px;
    }
  }

  @media screen and (min-width: 1700px) {
    top: 113px;
    p {
      font-size: 63px;
      line-height: 62px;
    }

    article {
      font-size: 34px;
      line-height: 39px;
      margin: 32px 0;
    }
  }
`
