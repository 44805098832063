import React from 'react'
//import { Link } from 'react-scroll'
import Picture from '../../../components/Picture'
import bannerTopoImg from '../../../assets/images/dia-das-maes/main-banner.png'
import bannerTopoMobileImg from '../../../assets/images/dia-das-maes/banner-topo-lojas-2-mobile.png'

import * as S from './styles'

const BannerTopo = () => (
  <S.BannerTopo>
    <Picture
      xs={bannerTopoMobileImg}
      md={bannerTopoImg}
      lg={bannerTopoImg}
      alt="Dia das mães"
    />
  </S.BannerTopo>
)

export default BannerTopo
