import React, { useEffect, useState } from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import BannerTopo from '../../components/Dia-das-maes/banner-topo-imagem'
import FormNewsletter from '../../components/Newsletter'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import { ditoTrack } from '../../helpers/dito'
import Modal from 'react-modal'
import { graphql } from 'gatsby'

import {
  gelNimbus25,
  gelCumulus24,
  novaBlast,
  gelResolution,
  imgBolsaUnissex,
  imgPochetteUnissex,
  imgViseiraUnissex,
  imgBoneUnissex,
  imgMapa,
  imgWaze,
  imgGoogleMaps,
  btnWhatsapp,
} from '../../images/dia-das-maes'

import * as S from './styles'

const Lojas = () => {
  useEffect(() => {
    ditoTrack('acessou-lp-natal')
  }, [])

  Modal.setAppElement('#___gatsby')

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [store, setStore] = useState({})

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      maxWidth: '700px',
      overflowX: 'auto',
    },
  }

  const lojasSP = [
    {
      title: 'Oscar Feire',
      address: 'Rua Oscar Freire, 1082',
      linkWaze:
        'https://www.waze.com/pt-BR/live-map/directions?to=ll.-23.561876%2C-46.670372',
      linkGoogleMaps: 'https://goo.gl/maps/iYXhR9c1xkkBtgMS9',
    },
    {
      title: 'Morumbi',
      address: 'Avenida Roque Petroni Junior nº 1089 LUC 14S',
      linkWaze:
        'https://www.waze.com/pt-BR/live-map/directions?to=ll.-23.625305%2C-46.692603',
      linkGoogleMaps: 'https://goo.gl/maps/EXKrM4U4sBCY1urA7',
    },
    {
      title: 'Itupeva',
      address: 'Rod. dos Bandeirantes, Km 72 Loja 45',
      linkWaze:
        'https://www.waze.com/pt-BR/live-map/directions?to=ll.-23.108304%2C-47.008975',
      linkGoogleMaps: 'https://goo.gl/maps/cRRYj9VC8GQAQnhD8',
    },
    {
      title: 'OUT Guarulhos',
      address: 'Rodovia Presidente Dutra, Km 222 3 Loja N B-19/B-20',
      linkWaze: 'https://waze.com/ul/h6gyfmqe0v',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/S%C3%B3+Marcas+Outlet+Guarulhos/@-23.4751467,-46.523748,19z/data=!4m10!1m2!2m1!1sRodovia+Presidente+Dutra,+Km+222+3+!3m6!1s0x94ce5fe5d81a2e8b:0xf6d6f2947040ea8e!8m2!3d-23.4751467!4d-46.5230973!15sCiJSb2RvdmlhIFByZXNpZGVudGUgRHV0cmEsIEttIDIyMiAzWiMiIXJvZG92aWEgcHJlc2lkZW50ZSBkdXRyYSBrbSAyMjIgM5IBD3Nob3BwaW5nX2NlbnRlcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VOMWMxQXlaSEpuUlJBQuABAA!16s%2Fg%2F11h0hq4390',
    },
    {
      title: 'OUT Catarina',
      address: 'Rua Rafael Dias Costa, 140 Loja 97',
      linkWaze: 'https://waze.com/ul/h6gy6yb3pe',
      linkGoogleMaps:
        'https://www.google.com.br/maps/search/Rua+Rafael+Dias+Costa,+140+Loja+97/@-23.4201508,-47.163448,18z/data=!3m1!4b1',
    },
  ]

  const lojasRJ = [
    {
      title: 'Barra',
      address: 'Avenida das Américas Nº 4666 Loja 221 ABC',
      linkWaze:
        'https://www.waze.com/pt-BR/live-map/directions?to=ll.-22.997668%2C-43.358123',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/ASICS/@-22.9978733,-43.3599291,17z/data=!3m1!4b1!4m5!3m4!1s0x9bda3808eb0001:0x7483f5b153ebc5ab!8m2!3d-22.9978733!4d-43.3577404',
    },
    {
      title: 'Rio Duque de Caxias',
      address: 'Av Dona Tereza Cristina, 2000 Ljs LUC 1003 e 1004B',
      linkWaze: 'https://waze.com/ul/h75cnyzv6t',
      linkGoogleMaps:
        'https://www.google.com.br/maps/search/Av+Dona+Tereza+Cristina,+2000+Ljs+LUC+1003+e+1004B+Ch%C3%A1caras+Rio+Petr%C3%B3polis/@-22.6768357,-43.2883347,19z',
    },
  ]

  const lojasCE = [
    {
      title: 'Fortaleza',
      address: 'Rua Desembargador Lauro Nogueira, 1500 LJ LUC 2163',
      linkWaze:
        'https://www.waze.com/pt-BR/live-map/directions?to=ll.-3.741939%2C-38.472512',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/R.+Des.+Lauro+Nogueira,+1500+-+Papicu,+Fortaleza+-+CE,+60176-065/@-3.7409791,-38.4729771,17.25z/data=!3m1!5s0x7c74631714b60a1:0xa033b29356efbf0b!4m5!3m4!1s0x7c74633cfddc119:0x2584c7af5f35219!8m2!3d-3.7408139!4d-38.4710308',
    },
  ]

  const lojasMG = [
    {
      title: 'Contagem',
      address: 'Av.Cardeal Eugenio Pacelli, 1336 Loja 205',
      linkWaze: 'https://waze.com/ul/h7h2wqpx4p',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/Av.+Cardeal+Eug%C3%AAnio+Pacelli,+1336+-+Loja+205+-+Cidade+Industrial,+Contagem+-+MG,+32210-140/@-19.9534906,-44.0251696,17z/data=!3m1!4b1!4m5!3m4!1s0xa695fc45dec505:0x1b9b309c2cb57282!8m2!3d-19.9534906!4d-44.0229809',
    },
  ]

  const lojasGO = [
    {
      title: 'Brasília',
      address: 'Rod BR 060, SN KM22 LJ LUC 1025',
      linkWaze:
        'https://www.waze.com/pt-BR/live-map/directions?to=ll.-23.107316%2C-47.008502',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/Parque+Shopping+Barueri/@-23.5163178,-46.8577141,17z/data=!3m1!4b1!4m5!3m4!1s0x94cf0185979939bf:0xc4e79db350f8388!8m2!3d-23.5163178!4d-46.8555254',
    },
  ]

  const lojasBA = [
    {
      title: 'Camaçari',
      address: 'Estrada do Coco, SN LJ LUC 1002B e 1003',
      linkWaze: 'https://waze.com/ul/h7jsz11n2y',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/ASICS+Outlet/@-12.8259155,-38.2700305,17z/data=!3m1!4b1!4m5!3m4!1s0x7163f70994395e1:0x9ec40fde4faf1410!8m2!3d-12.8259155!4d-38.2678418',
    },
  ]

  const lojasRS = [
    {
      title: 'Novo Hamburgo',
      address: 'Rua Rincao, 505 Lj LUC 144',
      linkWaze: 'https://waze.com/ul/h6fexhq8re',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/R.+Rinc%C3%A3o,+505+-+Oper%C3%A1rio,+Novo+Hamburgo+-+RS,+93310-460/@-29.6701732,-51.1404831,19.25z/data=!4m13!1m7!3m6!1s0x9519439d9349857d:0x1f5f1c9cfd44c4e1!2sR.+Rinc%C3%A3o,+505+-+Oper%C3%A1rio,+Novo+Hamburgo+-+RS,+93310-460!3b1!8m2!3d-29.6699967!4d-51.1408827!3m4!1s0x9519439d9349857d:0x1f5f1c9cfd44c4e1!8m2!3d-29.6699967!4d-51.1408827',
    },
  ]

  const lojasSC = [
    {
      title: 'Porto Belo',
      address: 'Marginal Leste - BR 101, KM 159 - Lojas 1015/1016/1017',
      linkWaze: 'https://waze.com/ul/h6gjmm8r9j',
      linkGoogleMaps:
        'https://www.google.com.br/maps/place/Porto+Belo+Outlet+Premium/@-27.2005598,-48.6175335,17z/data=!3m1!4b1!4m5!3m4!1s0x94d8aec8b2e82b0d:0xf2d8ef3008b657b6!8m2!3d-27.2005598!4d-48.6153448',
    },
  ]

  const handleShowStore = (store) => {
    setModalIsOpen(true)
    setStore(store)
  }

  const calcados = [
    {
      title: 'GEL-NIMBUS™ 25',
      imgProduto: gelNimbus25,
      link: 'https://www.asics.com.br/Calcados?filtro_87=GEL-Nimbus-25&utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
    {
      title: 'GEL-CUMULUS™ 24',
      imgProduto: gelCumulus24,
      link: 'https://www.asics.com.br/Calcados?filtro_87=GEL-Cumulus-24&utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
    {
      title: 'NOVABLAST™ 3',
      imgProduto: novaBlast,
      link: 'https://www.asics.com.br/Calcados?filtro_87=Novablast-3&utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
    {
      title: 'GEL-RESOLUTION ™ 9',
      imgProduto: gelResolution,
      link: 'https://www.asics.com.br/calcados?filtro_87=GEL-Resolution-8&utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
  ]

  const acessorios = [
    {
      title: 'BOLSA UNISSEX',
      imgProduto: imgBolsaUnissex,
      link: 'https://www.asics.com.br/acessorios/bolsas-e-mochilas?utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
    {
      title: 'POCHETE UNISSEX',
      imgProduto: imgPochetteUnissex,
      link: 'https://www.asics.com.br/acessorios/bolsas-e-mochilas?utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
    {
      title: 'VISEIRA UNISSEX',
      imgProduto: imgViseiraUnissex,
      link: 'https://www.asics.com.br/acessorios/bones-e-viseiras?utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
    {
      title: 'BONÉ UNISSEX',
      imgProduto: imgBoneUnissex,
      link: 'https://www.asics.com.br/acessorios/bones-e-viseiras?utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas',
    },
  ]

  return (
    <Layout>
      <SEO title="Dia das mães" />
      <BannerTopo />
      <S.AsicsBack>
        <S.WrapperAsicsBack>
          <S.ItemAsicsBack>
            <S.ImgAsicsBackTitle />
          </S.ItemAsicsBack>
          <S.ItemAsicsBack>
            <S.TextWrapperAsicsBack>
              <p>
                O <strong>clube de benefícios</strong> <br />
                que inspira o movimento
              </p>
              <S.ButtonNewsLetter>
                <a
                  href="https://back.asics.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CONHEÇA AGORA
                </a>
              </S.ButtonNewsLetter>
            </S.TextWrapperAsicsBack>
          </S.ItemAsicsBack>
        </S.WrapperAsicsBack>
      </S.AsicsBack>

      <S.brinde>
        <S.WrapperBrinde>
          <S.ItemBrinde>
            <S.ImgBrinde />
          </S.ItemBrinde>
          <S.ItemBrinde>
            <S.TextWrapperBrinde>
              <p>
                Compre acima de R$ 500 e{' '}
                <strong>ganhe um presente especial da Granado</strong>
              </p>
            </S.TextWrapperBrinde>
          </S.ItemBrinde>
        </S.WrapperBrinde>
      </S.brinde>

      <S.BlocoProdutos>
        <h2>
          SEU PRODUTO <strong>IDEAL ESTÁ NA ASICS</strong>
        </h2>
        <S.ContainerProdutos>
          <S.Title>Calçados</S.Title>
          <S.WrapperProdutos>
            {calcados.map((calcado, index) => (
              <S.ItemProduto key={index}>
                <S.TitleProduto>{calcado.title}</S.TitleProduto>
                <S.ImagemProdutoWrap>
                  <S.ImgProduto src={calcado.imgProduto} alt={calcado.title} />
                </S.ImagemProdutoWrap>

                <S.ButtonProduto>
                  <a href={calcado.link} target="_blank" rel="noreferrer">
                    COMPRE AGORA
                  </a>
                </S.ButtonProduto>
              </S.ItemProduto>
            ))}
          </S.WrapperProdutos>
        </S.ContainerProdutos>
      </S.BlocoProdutos>

      <S.Vestuario>
        <S.TitleVestuario>Vestuário</S.TitleVestuario>
        <S.WrapperVestuario>
          <S.ItemVestuario className="feminino"></S.ItemVestuario>
          <S.ItemVestuario className="masculino"></S.ItemVestuario>
        </S.WrapperVestuario>
        <S.ButtonProduto>
          <a
            href="https://www.asics.com.br/Vestuario?filtro_39=Feminino&utm_source=lab&utm_medium=social&utm_campaign=crm-lp-lojas"
            target="_blank"
            rel="noreferrer"
          >
            COMPRE AGORA
          </a>
        </S.ButtonProduto>
      </S.Vestuario>

      <S.BlocoProdutos>
        <S.ContainerProdutos>
          <S.Title>Acessórios</S.Title>
          <S.WrapperProdutos>
            {acessorios.map((calcado, index) => (
              <S.ItemProduto key={index}>
                <S.TitleProduto>{calcado.title}</S.TitleProduto>
                <S.ImagemProdutoAcessoriosWrap>
                  <S.ImgProduto src={calcado.imgProduto} alt={calcado.title} />
                </S.ImagemProdutoAcessoriosWrap>
                <S.ButtonProduto>
                  <a href={calcado.link} target="_blank" rel="noreferrer">
                    COMPRE AGORA
                  </a>
                </S.ButtonProduto>
              </S.ItemProduto>
            ))}
          </S.WrapperProdutos>
        </S.ContainerProdutos>
      </S.BlocoProdutos>

      <S.Lojas id="lojas">
        <S.ContainerProdutos>
          <S.TitleLoja>
            NAS LOJAS SELECIONADAS, VOCÊ APROVEITA
            <strong> ATÉ 40% OFF</strong>
          </S.TitleLoja>
          <S.WrapperLojas>
            <S.ListaEnderecos>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('SP')}
                  rel="noreferrer"
                >
                  SÃO PAULO
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('CE')}
                  rel="noreferrer"
                >
                  CEARÁ
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('RJ')}
                  rel="noreferrer"
                >
                  RIO DE JANEIRO
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('RS')}
                  rel="noreferrer"
                >
                  RIO GRANDE DO SUL
                </a>
              </S.ButtonProduto>
            </S.ListaEnderecos>

            <S.ListaEnderecos>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('MG')}
                  rel="noreferrer"
                >
                  MINAS GERAIS
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('GO')}
                  rel="noreferrer"
                >
                  GOIÁS
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('BA')}
                  rel="noreferrer"
                >
                  BAHIA
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="javascript:void(0)"
                  onClick={() => handleShowStore('SC')}
                  rel="noreferrer"
                >
                  SANTA CATARINA
                </a>
              </S.ButtonProduto>
            </S.ListaEnderecos>
            <S.imgMapa>
              <img src={imgMapa} alt="mapa loja" />
            </S.imgMapa>
            <S.InfoLojas>
              <S.ButtonProduto>
                <a
                  href="https://www.asics.com.br/ajuda/lojas"
                  target="_blank"
                  rel="noreferrer"
                >
                  CONHEÇA NOSSAS LOJAS
                </a>
              </S.ButtonProduto>
              <S.ButtonProduto>
                <a
                  href="https://api.whatsapp.com/send?phone=551150568450&text=Ola+preciso+de+ajuda"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnWhatsapp} alt="whatsapp" />
                  FALE AGORA
                </a>
              </S.ButtonProduto>
            </S.InfoLojas>
            <i>
              *Consulte regulamento completo em: Institucional ASICS Brasil.
            </i>
          </S.WrapperLojas>
        </S.ContainerProdutos>
      </S.Lojas>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        style={customStyles}
      >
        <button
          className="fechar-modal"
          aria-label="Fechar modal"
          onClick={handleModalClose}
        >
          <FaTimes />
        </button>
        <S.TableLojas>
          <thead>
            <tr>
              <th>Loja</th>
              <th>Endereço</th>
              <th>Localização</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              switch (store) {
                case 'SP':
                  return lojasSP.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'RJ':
                  return lojasRJ.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'CE':
                  return lojasCE.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'MG':
                  return lojasMG.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'GO':
                  return lojasGO.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'BA':
                  return lojasBA.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'RS':
                  return lojasRS.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                case 'SC':
                  return lojasSC.map((loja, index) => (
                    <tr key={index}>
                      <td>{loja.title}</td>
                      <td>{loja.address}</td>
                      <td className="links-td">
                        <a
                          href={loja.linkWaze}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgWaze} alt />
                        </a>
                        <a
                          href={loja.linkGoogleMaps}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={imgGoogleMaps} alt />
                        </a>
                      </td>
                    </tr>
                  ))
                default:
                  return null
              }
            })()}
          </tbody>
        </S.TableLojas>
      </Modal>

      <S.WrapperNewsletter id="newsLetterWrap">
        <S.ContentNewsletter>
          <h2>CADASTRE SEU E-MAIL PARA RECEBER OFERTAS E NOVIDADES</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default Lojas

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
