import styled from 'styled-components'

import {
  titleAsicsBack,
  bgCinzaVestuario,
  bgVestuarioFeminino,
  bgVestuarioMasculino,
  BrindeGranado,
} from '../../images/dia-das-maes'

export const AsicsBack = styled.section`
  background-color: #e94a31;
  background-size: cover;
  width: 100%;
  padding: 20px 0;
  @media screen and (max-width: 767px) {
    background-size: cover;
    padding: 70px 0;
  }

  @media screen and (min-width: 1700px) {
    padding: 33px 0;
    background-size: cover;
  }
`

export const WrapperAsicsBack = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 0;
  }

  @media (min-width: 992px) {
    flex-flow: row;
  }

  @media (min-width: 1024px) and (min-width: 1366px) {
    max-width: 820px;
  }

  @media (min-width: 1700px) {
    max-width: 920px;
  }
`

export const ItemAsicsBack = styled.div`
  //margin-right:30px;
`

export const ImgAsicsBackTitle = styled.div`
  display: inline-block;
  width: 265px;
  height: 190px;
  background-image: url(${titleAsicsBack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 767px) {
    margin-top: 60px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 1024px) and (max-width: 1366px) {
    width: 265px;
  }

  @media (min-width: 1700px) {
    width: 265px;
  }
`

export const TextWrapperAsicsBack = styled.div`
  //align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  font-family: 'ASICSFont3.0-Regular', sans-serif;
  font-size: 18px;

  strong {
    color: #001e62;
  }
  @media (max-width: 991px) {
    text-align: center;
    width: 100%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 15px;
    width: 100%;
  }

  p {
    color: #fff;
    font-size: 24px;
    margin-bottom: 15px;
    @media screen and (min-width: 1700px) {
      font-size: 35px;
      line-height: 38px;
      margin-bottom: 22px;
    }

    @media (min-width: 990px) and (max-width: 1366px) {
      font-size: 25px;
    }
  }
`

export const LiveUp = styled.div`
  width: 250px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
`

export const brinde = styled.div`
  display: none;
`

export const WrapperBrinde = styled.div`
  display: flex;
  background-color: #b6bbdf;
  position: relative;
  justify-content: flex-end;
  padding: 40px;
  width: 1190px;
  margin: 60px auto;
  height: 340px;
  line-height: 49px;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
`

export const ItemBrinde = styled.div`
  display: flex;
`

export const ImgBrinde = styled.div`
  position: absolute;
  left: 100px;
  top: -70px;
  background-image: url(${BrindeGranado});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 174px;
  height: 389px;
  @media (max-width: 991px) {
    position: relative;
    left: 0;
    top: 0;
  }
`

export const TextWrapperBrinde = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 48px;
  width: 780px;
  @media (max-width: 991px) {
    width: 100%;
    font-size: 24px;
    text-align: center;
    padding: 0 20px;
    line-height: 30px;
  }
`
export const LiveUpImg = styled.img`
  width: 100%;
  height: auto;
`

export const BlocoProdutos = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 35px 0;
  background-color: #e5e4e2;
  h2 {
    color: #001e62;
    font-family: 'ASICSFont3.0-Italic';
    text-align: center;
    font-size: 28px;
    margin-bottom: 50px;

    strong {
      font-family: 'ASICSFont3.0-BoldItalic';
    }

    @media screen and (max-width: 767px) {
      width: 220px;
      margin: 0 auto;
    }

    @media screen and (min-width: 1700px) {
      font-size: 42px;
      margin-bottom: 63px;
      padding: 77px 0;
      padding-bottom: 0;
    }
  }
`

export const Title = styled.h3`
  width: 220px;
  color: #001e62;
  font-family: 'ASICSFont3.0-Italic';
  text-align: center;
  position: relative;
  text-align: right;
  font-size: 24px;
  padding-bottom: 5px;
  margin-bottom: 30px;
  margin-left: 210px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: #e94a31;
    width: 220px;
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    text-align: right;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1700px) {
    font-size: 34px;
    margin-left: 222px;
    &::before {
      height: 3px;
    }
  }
`

export const TitleLoja = styled(Title)`
  width: 100%;
  margin: 0;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 400;
  color: #001e62;

  strong {
    color: #e94a31;
    font-weight: bold;
  }
  &::before {
    background: transparent;
    width: auto;
  }

  @media screen and (min-width: 1024px) {
    br {
      display: none;
    }
  }

  @media screen and (min-width: 1700px) {
    font-size: 40px;
  }
`

export const InfoLojas = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  margin: auto;
  gap: 20px;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 170px !important;
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }

    @media screen and (min-width: 1700px) {
      font-size: 15px;
      width: 225px !important;
    }
  }
`

export const ContainerProdutos = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 1700px) {
    max-width: 1325px;
  }
`

export const WrapperProdutos = styled.div`
  display: flex;
  gap: 20px;
  text-align: center;
  color: #001e62;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 0;
  }
`

export const ItemProduto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 12px 0px #00000026;
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 30px;
  padding: 35px;
  width: 265px;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    min-height: 400px;
  }

  @media screen and (min-width: 1700px) {
    padding: 65px 20px;
    width: 370px;
  }
`

export const TitleProduto = styled.div`
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 22px;
  color: #e94a31;

  @media screen and (min-width: 1700px) {
    font-size: 31px;
    z-index: 10;
  }
`

export const ImagemProdutoWrap = styled.div`
  min-height: 195px;
  align-items: center;
  display: flex;
  @media screen and (min-width: 1700px) {
    margin: 15px 0;
    height: 100px;
  }
`

export const ImagemProdutoAcessoriosWrap = styled(ImagemProdutoWrap)`
  height: auto;
  margin-bottom: 10px;
`

export const ImgProduto = styled.img`
  opacity: 1;
  width: 100%;
  height: auto;
`

export const ButtonProduto = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  a {
    background-color: #4a5fc4;
    border: none;
    border-radius: 50px;
    color: #fff;
    font-family: 'ASICSFont3.0-Bold', sans-serif;
    font-size: 11px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    width: 165px;

    @media screen and (min-width: 1700px) {
      font-size: 15px;
      width: 185px;
    }

    @media screen and (max-width: 767px) {
      font-size: 15px;
    }

    &:hover {
      background-color: #5dc095;
    }
  }

  &.btn-sp-mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }

  &.btn-sp-desktop {
    display: flex;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`

export const Vestuario = styled(BlocoProdutos)``

export const TitleVestuario = styled(Title)`
  @media (min-width: 992px) and (max-width: 1366px) {
    margin-left: 320px;
  }
  @media (min-width: 1367px) and (max-width: 1699px) {
    margin-left: 25%;
  }
  @media screen and (min-width: 1700px) {
    //margin-left: 520px;
    margin-left: 27.3%;
  }
`

export const WrapperVestuario = styled.div`
  background-image: url(${bgCinzaVestuario});
  background-size: cover;
  background-position: right center;
  display: flex;
  margin-bottom: 15px;
  @media screen and (max-width: 991px) {
    background-position: center;
  }
`

export const ItemVestuario = styled.div`
  display: flex;
  align-items: flex-end;
  width: 50%;
  height: 327px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  &.feminino {
    background-image: url(${bgVestuarioFeminino});
  }
  &.masculino {
    background-image: url(${bgVestuarioMasculino});
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    background-size: cover;
  }

  a {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1700px) {
    height: 435px;
  }
`

export const WrapperComprar = styled.section`
  padding: 50px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 50px 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 50px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 50px;
  }
`

export const TitleComprar = styled.h2`
  color: #fa8452;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 28px;
  margin-bottom: 5px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`

export const TextComprar = styled.p`
  color: #9697a9;
  font-size: 18px;
  font-style: italic;
`

export const ContentParaQuem = styled.div`
  margin: 0 auto 40px auto;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 767px) {
    text-align: center;
  }
`

export const Lojas = styled.section`
  background-color: #fff;
  padding: 65px 0 0 0;
  margin-bottom: 50px;
  @media screen and (min-width: 1700px) {
    padding: 90px 0 0 0;
  }
`

export const WrapperLojas = styled(WrapperProdutos)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    i {
      margin-top: 20px;
    }
  }
`

export const ContentEspecificacoes = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const PrecoModelo = styled.span`
  color: #0485c8;
  display: block;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 60px;

  @media (max-width: 767px) {
    margin-top: 30px;
    text-align: center;
  }
`

export const ListaEnderecos = styled.div`
  display: flex;
  margin-top: 30px;
  margin: 0 auto;
  gap: 10px;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    a {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
`

export const imgMapa = styled.div`
  width: 200px;
  margin: auto;
  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const ButtonNewsLetter = styled.div`
  a {
    background-color: #fff;
    border-radius: 99999px;
    color: #3a2f47;
    display: block;
    font-family: 'ASICSFont3.0-Bold', sans-serif;
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    max-width: 130px;
    padding: 7px 0 3px 0;
    margin: 0 auto;
    &:hover {
      background-color: #5dc095;
      color: #fff;
    }

    @media (max-width: 767px) {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      padding: 13px 22px;
      max-width: 190px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 30px;
      margin: 0 auto;
    }

    @media screen and (min-width: 1700px) {
      max-width: 188px;
      padding: 12px 14px 10px 14px;
      font-size: 16px;
    }
  }
`

export const TableLojas = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
  overflow-x: auto;
  thead {
    background-color: #3a2f47;
    th {
      color: #fff;
      padding: 10px;
      font-family: 'ASICSFont3.0-Regular', sans-serif;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #ebebeb;
        padding: 10px;
        font-family: 'ASICSFont3.0-Regular', sans-serif;
        text-align: center;
        img {
          width: 30px;
          height: 30px;
        }

        &.links-td {
          display: flex;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }
`

export const WrapperNewsletter = styled.section`
  padding: 70px 0 50px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 15px 30px 15px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 28px;
    margin-bottom: 5px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  img {
    position: absolute;
    right: 90px;
    top: 60px;
    width: 400px;

    @media (max-width: 991px) {
      display: none;
    }
  }
`

export const FormNewsletter = styled.div`
  margin-top: 30px;
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
